import React from "react";
import ballslogo from '../img/ballsT.png'
import telegram from '../img/telegram.png'
import twitter from '../img/twitter.png'
import chart from '../img/chart.png'
import balls from '../img/balls.png'
import ballsonsolana from '../img/ballsonsolana.jpg'
import Piechart from '../img/piechart.png'
import ballpaper from '../img/ballpaper.png'
import meme1 from '../img/meme1.png'
import meme2 from '../img/meme2.png'
import meme3 from '../img/meme3.png'
import pinksale from '../img/pinksale.png'

class Home extends React.Component {
  render() {
    return (
      <div className="home">
        <img src={ballslogo} alt="logo" />
        <img src={balls} alt="balls" />
        <h1> Join if you have balls?</h1>
        <div className="logos-horizontal">
            <a href="https://t.me/solanaballs"><img src={telegram} alt="telegram logo" /></a>
            <a href="https://twitter.com/solanaballs"><img src={twitter} alt="twitter logo" /></a>
            <a href="#"><img src={chart} alt="chart logo" /></a>
        </div>
        <div className="header">
          <div className="text">
            <h1> Solana Balls </h1>
            <p>Supppp, if you love solana you are gonna love solana balls too. Coz only with the ones who have balls can buy $BALLS</p>
            <button className="custom-btn btn-6">Buy $BALLS</button>
          </div>
          <img src={ballsonsolana} alt="balls on solana" />
        </div>
        <h1 style={{marginTop: '150px', fontSize: '40px'}}> Ballnomics and BallPaper</h1>
        <div className="info">
          <img src={Piechart} alt="PieChart" />
          <a href="https://justpaste.it/ayh0c"><img src={ballpaper} alt="ballpaper" /></a>
        </div>

        <h1 style={{marginTop: '150px', fontSize: '40px'}}> Balls of Fame.....</h1>
        <div className="memes">
          <img src={meme1} alt="meme1" />
          <img src={meme2} alt="meme2" />
          <img src={meme3} alt="meme3" />
        </div>

        <h1 style={{marginTop: '150px', fontSize: '40px'}}> Partners </h1>
        <div className="pinksale">
          <img src={pinksale} alt="pinksale" />
        </div>

        <h1 style={{marginTop: '150px', fontSize: '40px'}}> Disclaimer!!</h1>
        <h1 style={{marginTop: '-20px', fontSize: '20px', width: '350px'}}> Solana Balls Token is a meme project created solely for entertainment purposes and does not constitute financial advice or an investment opportunity. Participation in the project carries inherent risks, including potential loss of funds.</h1>
      </div>
    );
  }
}

export default Home;
